import { FsOrgEnvironment } from './fs-org-environment';

export const environment: FsOrgEnvironment = {
  production: false,
  apiUrl: 'https://core.dev.env.fairandsmart.tech/api',
  wsUrl: 'wss://core.dev.env.fairandsmart.tech/sub',
  authUrl: 'https://auth.dev.env.fairandsmart.tech/auth',
  catalogUrl: 'https://hub.dev.env.fairandsmart.tech/catalog',
  hubUrl: 'PUT_HUB_HOST_HERE',
  authRealm: 'FairAndSmart',
  shareUrl: 'https://share.dev.env.fairandsmart.tech',
  authClientId: 'fsorg',
  stripePublishableKey: 'pk_test_bkv1EATpn1IySChXKf10JlMq',
  openDataSoftApiKey: 'c50f98298454dd6a9cdfddd0bf6f1d1eeee34643e62711efde65a96e',
  assetsUrl: 'https://assets.fairandsmart.tech',
  confluenceUrl: 'https://fairandsmart.atlassian.net/wiki',
  privacyPlatform: {
    root: 'at.fairandsmart.tech',
    host: 'https',
  },
  customization: {
    appName: 'Fair[&]Smart Pro',
    pageTitle: 'Fair[&]Smart Pro',
    faviconUrl: 'https://assets.fairandsmart.tech/favicon_fs.ico',
    splashLogoUrl: 'https://assets.fairandsmart.tech/logo_fs.svg',
    splashLogoStyle: 'height: 160px;',
    headerLogoUrl: 'https://assets.fairandsmart.tech/logo_fs_tagline.png',
    headerLogoStyle: 'height: 59px;',
    allowAccountCreation: true as any,
    allowOrganisationCreation: true as any,
    showHelpMenu: true as any,
    showOfferSection: true as any,
    showRegistryConfigSection: true as any,
    showCppSection: true as any,
    autoAcceptCgu: false as any,
    footerLegalsUrl: 'https://www.fairandsmart.com/mentions-legales/',
    footerContactUrl: 'https://www.fairandsmart.com/contact/',
    footerSupportUrl: 'https://fairandsmart.atlassian.net/servicedesk/customer/portal/1',
    footerSocialLinks: true as any,
    footerCopyrights: '© fair[&]smart 2017-2023',
  },
  features: {
    cookiesUseLocalStorage: false as any,
  },
};
